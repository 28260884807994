import React,{useState,useEffect} from 'react';
import { Link,graphql } from 'gatsby';
import loadable from '@loadable/component'
// const Nav = loadable(() => import ('../components/Nav'))
// const Footer = loadable(() => import('../components/Footer'))
import Footer  from "../components/Footer";
import Nav from "../components/Nav";
import { onRouteUpdate } from '../../gatsby-browser';

function Download(installers){
    const  [user, setUser] = useState("")
    const windows_msi = installers?.data?.allMarkdownRemark?.nodes[0].frontmatter?.windows_msi
    const linux_zip = installers?.data?.allMarkdownRemark?.nodes[0]?.frontmatter?.linux_zip
    const windows_zip = installers?.data?.allMarkdownRemark?.nodes[0].frontmatter?.windows_zip
    const logo = installers?.data?.allMarkdownRemark?.nodes[0].frontmatter?.logo
    const version = installers?.data?.allMarkdownRemark?.nodes[0].frontmatter?.version
    const title = installers?.data?.allMarkdownRemark?.nodes[0].frontmatter?.title
    const subtitle = installers?.data?.allMarkdownRemark?.nodes[0].frontmatter?.subtitle
    const date = installers?.data?.allMarkdownRemark?.nodes[0].frontmatter?.date
    console.log("windows_zip", installers)
    useEffect(() => {
  
     setUser(localStorage.getItem("token"));
  
    })
  
    return(
<>
<Nav/>

    
        <section class="wow fadeIn bg-light-gray padding-35px-tb page-title-small top-space" style={{marginTop:"80px"}}>
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-8 col-md-6 d-flex flex-column justify-content-center">
                        <h1 class="alt-font text-extra-dark-gray font-weight-600 no-margin-bottom">Download</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="padding-70px-tb wow fadeInUp">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-8 md-margin-30px-bottom sm-margin-30px-bottom">
                        <div class="row justify-content-center">
                            <div class="col-12 col-lg-3 md-margin-30px-bottom sm-margin-30px-bottom">
                                <img src={logo} alt="" class="margin-15px-bottom"/>
                                <p class="margin-35px-left">Version: {version}<br/>{date}</p>
                            </div>
                            <div class="col-12 col-lg-1"></div>
                            <div class="col-12 col-lg-8">
                                <h5 class="title-section-alt alt-font margin-5px-bottom">{title}</h5>
                                <p class="text-extra-large">{subtitle}</p>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 col-lg-6 md-margin-30px-bottom sm-margin-30px-bottom">
                                <div class="bg-light-gray padding-25px-all">
                                    <h5 class="title-section-alt alt-font margin-20px-bottom">Windows</h5>
                                    <p class="text-large min-height-50px">
                                        <a href={windows_msi} class="text-blue" onClick={() => onRouteUpdate({ event_type: 'click', download_type: ".msi" })}>.msi</a><br/>
                                        <a href={windows_zip} class="text-blue" onClick={() => onRouteUpdate({ event_type: 'click', download_type: ".zip" })}>.zip</a>
                                    </p>
                                    <h6 class="alt-font margin-20px-bottom border-bottom padding-10px-bottom">System requirements</h6>
                                    <ul class="ppadding-30px-left mb-0 min-height-200px">
                                        <li>Windows 10 and 11 (32-bit and 64-bit)</li>
                                        <li>1.6 GHz or faster processor</li>
                                        <li>1 GB of RAM</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="bg-light-gray padding-25px-all">
                                    <h5 class="title-section-alt alt-font margin-20px-bottom">Linux</h5>
                                    <p class="text-large min-height-50px"><a href={linux_zip} class="text-blue" onClick={() => onRouteUpdate({ event_type: 'click', download_type: ".tar.gz" })}>.tar.gz</a></p>
                                    <h6 class="alt-font margin-20px-bottom border-bottom padding-10px-bottom">System requirements</h6>
                                    <ul class="padding-30px-left mb-0 min-height-200px">
                                        <li>Linux (Debian): Ubuntu Desktop 16.04, Debian 9</li>
                                        <li>Linux (Red Hat): Red Hat Enterprise Linux 7, CentOS 7, Fedora 34</li>
                                        <li>1.6 GHz or faster processor</li>
                                        <li>1 GB of RAM</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <ul>
                            <li><a href="https://marketplace.visualstudio.com/items?itemName=verum.dezyne" target="_blank">Visual Studio Code Extension</a></li>
                            <li><a href="https://download.verum.com/verum-dezyne/manual/verum-dezyne.pdf" target="_blank">Manual (PDF)</a></li>
                           
                            <li><a href="https://www.youtube.com/playlist?list=PLYeUJQE-xjAIANXF0kr35L2t1IimUmBfQ" target="_blank">YouTube tutorials</a></li>           
                            <li><a href="https://download.verum.com/download/verum-dezyne/" target="_blank">Other versions</a></li>
                            <li><a href="https://download.verum.com/blog.html" target="_blank">Release Notes</a></li>
                            <li><a href="https://forum.verum.com/c/known-issues/14" target="_blank">Known Issues</a></li>

                        </ul>                        
                    </div>
                </div>                               
            </div>
        </section>
        <Footer/>
   </>  
 )}
export default Download;

  
  export const InstallerQuery = graphql`
  query DownloadQuery {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(/Installers/)/"}}) {
      nodes {
        frontmatter {
          linux_zip
          windows_msi
          windows_zip
          logo
          version
          date
          title
          subtitle

        }
      }
    }
  }
  `
  
